/** @jsx jsx */

import MySEO from '../components/seo'
import Link from '../components/Link'
import Layout from '../components/layout'
import {Container,jsx,Box,Flex,Heading,Text} from 'theme-ui'

export default function Impressum(){
    return(
    <Layout>
        <Flex>
<Container >
<Box sx={{m:1}}>
<MySEO title="Impressum" description="Impressum" />

<Heading sx={{fontWeight:500,color:'text'}} as="h1"> Impressum </Heading>


<Heading sx={{color:'text',pt:20,pb:30}}> Verein Kulturnetz Oberes Mittelrheintal e.V.</Heading>
<Text>Amtsgericht Mainz<br/>
VR 42004
</Text>
<br/>
<Text>


Katrin Gloggengießer   <br/>
Langstr. 48, 55422 Bacharach<br/> 

Email: kontakt@kulturnetz-oberes-mittelrheintal.org

</Text>

<br/>

<Link sx={{color:`text`}} to="datenschutz">Datenschutz</Link>

</Box>
</Container>
</Flex>
    </Layout>
    )
}


